// Big text generator
// https://patorjk.com/software/taag/#p=display&f=Big&t=Type%20Something%20

@font-face {
  font-family: OpenSansB;
  src: url("./assets/font/OpenSans-ExtraBold.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

//   _____                           _
//  / ____|                         | |
// | |  __  ___ _ __   ___ _ __ __ _| |
// | | |_ |/ _ \ '_ \ / _ \ '__/ _` | |
// | |__| |  __/ | | |  __/ | | (_| | |
//  \_____|\___|_| |_|\___|_|  \__,_|_|

$metric: 0.5rem;
$white: rgb(255, 255, 255);
$grey: rgb(241, 241, 241);
$darkgrey: #e0e0e0;
$shadow: rgb(150, 150, 150);
$red: rgb(255, 89, 89);
$green: rgb(0, 219, 18);
$font_size: 1rem;
$border_size: 0.15rem;
$total_height: 100vh;
$transparentWhite: rgba(255, 255, 255, 0.5);
$gold: rgb(218, 185, 0);
$silver: #c0c0c0ff;
$bronze: #cd7f32ff;
$blue: #2353fe;
$black: rgb(17, 17, 17);
$transblack: rgba(0, 0, 0, 0.493);
$purple: #8e7eff;
$lightpurple: #c9c9c9;
// $purple: #ff0000;
$yellow: rgb(255, 217, 0);
$lightgrey: #e1e1e1;
$darkshadow: #000000c9;

h1,
h2,
h3 {
  font-weight: normal;
  // flex: 1;
}

.gameOverEventName {
  padding: $metric $metric * 5;
  font-size: $font_size * 1.4;
}

body {
  font-family: OpenSansB, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-position: center;
  background-repeat: none;
  background-size: cover;
  background-color: $purple;
}

select {
  font-family: OpenSansB, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
}

.calendar {
  margin: 0 auto;
}

.calendarContainer {
  .rdtPicker{
    display: contents !important; 
  }
  
}

.gameManager {
  height: 100vh;
  display: flex;
  flex-direction: column;
  place-content: space-between;
}

.blankScreen {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;

  div {
    // background-color: $white;
    color: $white;
    margin: auto;
    padding: $metric * 4;
    border-radius: $metric * 4;
  }
}

video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}

button,
input {
  font-family: OpenSansB, "NewF";

  font-size: $font_size * 1.2;

  background: $white;

  padding: $metric * 1.4 $metric * 1.8;
  border-radius: $metric * 4;
  transition: 0.25s;
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  // border-bottom: $border_size $darkshadow solid;
  z-index: 100;
}

.textarea {
  font-family: OpenSansB, "NewF";
  margin: auto;
  background: #f3f3f3;
  font-size: $font_size * 0.8;
  padding: $metric * 1.4 $metric * 1.8;
  border-radius: $metric * 2;
  transition: 0.25s;
  font-weight: normal;
  overflow: hidden !important;
  text-overflow: ellipsis;
  border-bottom: $border_size $darkshadow solid;
  z-index: 100;
  max-width: 80%;
}

.textarea:focus {
  outline: none;
}

button {
  border: 0;
}
input {
  border: $border_size solid $darkgrey;
}

button,
input:focus {
  outline: 0;
}

button {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  // box-shadow: 0 $border_size $black;
  transition: all 0.05s linear;
  img {
    height: 1 * $font_size;
  }
}

button:hover {
  // box-shadow: 0 $border_size/2 $black;
  // transform: translateY($border_size/2);
  // transform: scale(1.01);
}

.basicModal{
  padding: $metric*2;
  text-align: center;
  *{
    margin: $metric
  }
  button{
    color: $white;
    background-color: $purple;
  }
  .red{
    background-color: $red;
  }
}



.switchContainer {
  font-size: $font_size * 1;
  font-weight: normal;

  background-color: $white;
  width: fit-content;
  color: $black;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $metric/2 $metric/2 $metric/2 $metric * 2;
  border-radius: 4 * $metric;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-left: 2 * $metric;
  // box-shadow: 0 $border_size $black;
  h3 {
    display: inline-block;
  }
  .right {
    background-color: $purple;
    color: $white;
    padding: $metric $metric * 2;
    margin-left: $metric;
    border-radius: $metric * 3;
  }
  .timer {
    width: $metric * 5;
    text-align: center;
  }
}

.main {
  height: calc(100vh - 0.08rem);
  display: grid;
  grid-template-rows: $metric * 7 1fr;
  header,
  footer {
    display: flex;
    place-content: space-between;
    padding: $metric $metric * 1.5;
    place-items: center;

    background-color: rgba(255, 255, 255, 0.205);
    div {
      display: flex;
      align-items: center;
      .logo {
        height: $metric * 5;
        display: inline-block;
      }
      h1 {
        font-size: 2 * $font_size;
        margin-left: $metric;
      }
    }

    h1 {
      font-size: $font_size * 1.5;
      text-align: left;
    }
    .profile {
      display: flex;
      place-content: space-between;
      place-items: center;
      cursor: pointer;
      border-radius: $metric * 5;
      background-color: $purple;
      color: $white;
      text-align: right;

      svg {
        height: 0.8rem;
        vertical-align: middle;
        margin-left: $metric/2;
        text-align: right;
      }

      span {
        display: block;
        text-align: right;
        margin: 0 $metric * 1.5 0 $metric * 2;
        font-weight: normal;
      }
      span:nth-of-type(1) {
        font-size: $font_size * 1.2;
      }
      span:nth-of-type(2) {
        text-decoration: underline;
      }
      .profileImageContainer {
        height: $metric * 5.5;
        width: $metric * 5.5;
        border-radius: 100%;
      }
      .profileImage {
        width: calc(100%);
        height: calc(100%);
        border-radius: 150px;
        transform: rotateY(180deg);
        border: 4px $white solid;
      }
    }
  }
  header {
    border-bottom: $border_size solid $darkgrey;
    background: $white;
    color: $black;
  }
  footer {
    position: fixed;
    width: calc(100% - 1.5rem);
    display: flex;
    bottom: 0;

    .reactionsButton {
      margin-right: $metric;
      border-radius: 4 * $metric;
      z-index: 100;
      button {
        display: flex;
        align-items: center;
        padding-right: $metric * 2;
      }
      svg {
        display: inline-block;
        vertical-align: middle;
        height: 1.35 * $font_size;
        margin-right: $metric;
      }
    }
    display: flex;
    .dailyCoControls {
      align-items: middle;
      border-radius: 4 * $metric;
      button {
        margin-right: $metric;
        display: flex;
        align-items: center;
      }
      .leave {
        background: $red;
        color: white;
        svg {
          display: inline-block;
          vertical-align: middle;
          height: 1.5 * $font_size;

          width: 1.5 * $font_size;
          margin-right: $metric;
        }
      }
      .control {
        svg {
          display: inline-block;
          vertical-align: middle;
          height: 1.5 * $font_size;
          width: 1.5 * $font_size;
        }
      }
    }
  }
}

.tournamentContent {
  position: relative;
  color: $white;
}

//  _____                 _   _
// |  __ \               | | (_)
// | |__) |___  __ _  ___| |_ _  ___  _ __  ___
// |  _  // _ \/ _` |/ __| __| |/ _ \| '_ \/ __|
// | | \ \  __/ (_| | (__| |_| | (_) | | | \__ \
// |_|  \_\___|\__,_|\___|\__|_|\___/|_| |_|___/

.reactions {
  background: $white;
  border: $border_size solid $darkgrey;
  position: absolute;
  bottom: $metric * 8;
  right: $metric;
  height: 30 * $metric;
  width: 50 * $metric;
  border-radius: 2 * $metric;
  display: grid;
  justify-items: center;
  grid-template-rows: 1fr 6fr;
  padding: $metric;
  z-index: 50;
  .reactionsHeader {
    place-items: center;
    border-radius: 2 * $metric;
    padding: $metric;
    border: $border_size solid $darkgrey;
    svg {
      display: inline-block;
      vertical-align: middle;
      width: 2 * $metric;
      margin: 0 $metric;
      color: #797979;
    }
    input {
      font-size: $font_size;
      width: 42 * $metric;
      display: inline-block;
      border: 0;
      margin: 0;
      padding: 0;
      padding-left: $metric;
      &:hover {
        cursor: text;
      }
      &:focus {
        outline: none;
        background: white;
      }
    }
  }

  .reactionsFooter {
    button {
      margin-top: $metric;
      svg {
        height: 1.5 * $font_size;
        width: 1.5 * $font_size;
      }
    }
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: $metric/2;
    padding-top: $metric;
    overflow: auto;

    .wrapper {
      justify-items: center;
      overflow: auto;
      &.emoji {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;
        justify-content: center;
        font-size: $font_size * 2.3;
        line-height: $font_size * 2.3;
        &::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }

        div {
          margin: $font_size/5;
          &:hover {
            cursor: pointer;
          }
        }
      }

      &.gif {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        &::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }
        &:hover {
          cursor: pointer;
        }
        .giffy {
          img,
          div {
            height: $metric * 11;
            width: $metric * 11;
            object-fit: cover;
            margin: $metric/2;
            border-radius: $metric;
          }
        }
      }
    }
  }
}

//   _                    _           _                         _
//  | |                  | |         | |                       | |
//  | |     ___  __ _  __| | ___ _ __| |__   ___   __ _ _ __ __| |
//  | |    / _ \/ _` |/ _` |/ _ \ '__| '_ \ / _ \ / _` | '__/ _` |
//  | |___|  __/ (_| | (_| |  __/ |  | |_) | (_) | (_| | | | (_| |
//  |______\___|\__,_|\__,_|\___|_|  |_.__/ \___/ \__,_|_|  \__,_|

.leaderboard::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.leaderboard {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  width: 42 * $metric;
  margin: $metric;
  color: $black;

  display: inline-block;
  text-align: center;

  .top {
    background-color: $white;
    display: inline-block;
    border-top-left-radius: $metric * 3;
    border-top-right-radius: $metric * 3;
    display: auto;
    margin: auto;
    padding: $metric * 1 $metric * 4;
    padding-bottom: 0;
    h1 {
      text-align: center;
      font-size: $font_size * 1.5;
      display: inline-block;
    }

    img {
      height: $metric * 4;
      margin: auto;
      margin: 0 1.5 * $metric 0 0;
    }
  }
  .afterTitle {
    width: 100%;
    height: 2 * $metric;
    background-color: $white;

    margin: auto;
    margin-bottom: $metric/2;
    border-radius: $metric;

    border-top-left-radius: $metric * 2;
    border-top-right-radius: $metric * 2;
    border-bottom: $border_size $darkshadow solid;
  }

  .users {
    border-radius: $metric * 3;
    width: calc(100%);
  }
  .row {
    display: flex;
    flex-direction: row;
    padding: 0 $metric * 1;
    background-color: $white;
    border-radius: $metric * 2;
    height: 4.5$metric;
    margin: $metric/2 0;
    color: black;
    font-size: $font_size * 1.2;
    display: flex;
    align-items: center;
    border-bottom: $border_size $darkshadow solid;
    .position {
      flex: 1;
      color: rgba(0, 0, 0, 0.514);
      text-align: center;
      border-radius: $metric * 3;
      min-width: 3 * $font_size;
      max-width: 3 * $font_size;
      display: flex;
      align-items: center;
      div {
        height: 100%;
        width: 100%;
        margin: auto;
      }
      svg {
        height: $font_size * 1.75;
        margin: auto;
      }
    }

    .column {
      padding: $metric * 0;
      font-size: $font_size * 1.1;
      padding-left: $metric * 2;
      text-align: left;
      flex: 8;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      font-weight: normal;
      max-width: 13rem;
    }
    .score {
      padding: $metric * 0.75;
      padding-right: $metric * 2;
      text-align: right;
      display: flex;
      align-items: center;
      svg {
        display: inline-block;
        height: $font_size;
        margin-right: $metric/2;
        color: $yellow;
      }

      img {
        display: inline-block;
        height: $font_size * 1.5;
        margin-left: $metric/2;
        color: $yellow;
      }
    }
  }

  .podiumRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    .row {
      width: 100%;
    }
  }

  .trophies {
    height: $metric * 5;
    width: $metric * 16;
    margin-left: $metric;
    text-align: right;
    display: flex;
    align-items: center;
    background-color: #0000003b;
    color: $white;
    font-size: $font_size * 1.3;
    border-radius: $metric * 2;
    border-bottom: $border_size $black solid;
    text-align: center;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;
      margin: auto;
    }
    img {
      display: inline-block;
      height: $font_size * 1.5;
      margin-left: $metric/2;
      color: $yellow;
    }
  }

  .pos0 {
    border-color: $gold;
  }
  .pos1 {
    border-color: $silver;
  }
  .pos2 {
    border-color: $bronze;
  }
}

.p1 {
  width: 57 * $metric;

  .topContainer {
    max-width: calc(100% - 6.5rem);
  }
}
.topContainer{
  display: none;
}
//   _    _
//  | |  | |
//  | |  | |___  ___ _ __
//  | |  | / __|/ _ \ '__|
//  | |__| \__ \  __/ |
//   \____/|___/\___|_|

$userSize: 1;

.teamUser{
    padding: 10px;

    .namePlate{
      width: calc(100% - 2.5rem - 10px)
    }

}

.user {
  position: relative;
  display: inline-block;
  // margin: $metric/2;

  // border-radius: $metric;
  text-align: center;
  width: 100%;
  z-index: 0;

.namePlate{
  text-align: center;
  position: absolute;
}

.scoreplate{
  margin-left:auto;
}



  .user_emoji {
    z-index: 2;
    position: absolute;
    animation: emoji 0.5s linear infinite alternate;
  }
  .namename{
    padding-right: $font_size/2;
  }
  .userName {
    max-width: calc(100% - 1rem);

    .micMute{
      color:$white;
    }
    
    svg{
      height: $font_size*0.7;
      margin-right: $metric/4;
      color: $yellow;
    }
  }

  @keyframes emoji {
    from {
      transform: scale(1) translate(0,-10%);
    }
    to {
      transform: scale(1.3) translate(0, -10%);;
    }
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    font-size: $font_size * 0.95;
    margin-top: 0;
    line-height: $font_size;
    transform: translate(0, -2.4 * $font_size);
    height: $font-size;

    // background-color: $white;
    // width: fit-content;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    color: $white;
    margin: auto;
    line-height: 0;
    padding: $metric/1.5 2 * $metric;
    // padding-left: $metric;
    font-weight: normal;
    border-radius: $metric * 2;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% - 2.5rem);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    // border-bottom: $border_size $darkshadow solid;

    
  }
  h2 {
    font-size: 1.5 * $font_size;
    transform: translate(0, -1.75rem);
    svg {
      display: inline-block;
      height: $font_size * 1;
      margin-right: $metric/2;
      color: $yellow;
    }
    img {
      display: inline-block;
      height: $font_size * 1.7;
      margin-right: $metric/2;
      color: $yellow;
    }
  }

  .status {
    border-radius: 100%;
    height: 0.5 * $font-size;
    width: 0.5 * $font-size;
    margin-right: $metric/2;
    border: none;
  }

  .statusMic {
    position: absolute;
    z-index: 1;
    height: calc(100% * 0.1);
    width: calc(100% * 0.1);
    border-radius: 100%;
    transform: translate(calc(100% * 1.2), calc(100% * 7.5));
    background-color: rgb(255, 255, 255);
  }
  .statusCam {
    position: absolute;
    z-index: 1;
    height: calc(100% * 0.1);
    width: calc(100% * 0.1);
    border-radius: 100%;
    transform: translate(calc(100% * 0.5), calc(100% * 6.5));
    background-color: rgb(255, 255, 255);
  }

  .sfalse {
    background-color: $red;
  }
  .strue {
    background-color: $green;
  }

  .cam {
    position: absolute;
    z-index: 2;
    height: calc(100% * 0.1);
    width: calc(100% * 0.1);
    border-radius: 100%;
    transform: translate(calc(100% * 0.5), calc(100% * 6.5));
    color: black;
    display: flex;
    align-items: center;

    div {
      margin: auto;
      width: 90%;
    }

    svg {
      height: calc(100% * 0.6);
      margin: auto;
    }
  }

  .mic {
    position: absolute;
    z-index: 2;
    height: calc(100% * 0.1);
    width: calc(100% * 0.1);
    border-radius: 100%;
    transform: translate(calc(100% * 1.2), calc(100% * 7.5));
    color: black;
    display: flex;
    align-items: center;

    div {
      margin: auto;
      width: 90%;
    }

    svg {
      height: calc(100% * 0.6);
      margin: auto;
    }
  }

  .userImage {
    border-radius: $metric;
    height: calc(100% - 1.2rem);
    width: calc(100% - 1.2rem);
    border-radius: 1rem;
    // border-radius: 50%;
    object-fit: cover;
    border: $metric $white solid;
    // box-shadow: 0px $border_size 0px #000000;
  }

  .crown {
    // position: absolute;
    // width: calc(100% * 0.27);
    // transform: translate(calc(100% * -0.3), calc(100% * -0.3)) rotate(-35deg);
    color: $yellow;
    // z-index: 1;
  }

  .speaking {
    // box-shadow: 0px 0px 0px $border_size * 2 $green;
    border-color: $green;
  }
}

//        _       _
//       | |     (_)
//       | | ___  _ _ __
//   _   | |/ _ \| | '_ \
//  | |__| | (_) | | | | |
//   \____/ \___/|_|_| |_|

.joinMain {
  background-color: $white;
  width: fit-content;
  text-align: center;
  border-radius: $metric * 4;
  margin: auto;
  margin-top: 4 * $metric;
  padding: $metric * 2;

  .topLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin: auto;
    margin: $metric * 2 auto $metric * 1;
    img {
      height: $font_size * 2.5;
      margin-right: $metric;
    }
  }
}

.join {
  text-align: center;
  padding: $metric * 2;

  .picture {
    align-items: center;
    display: flex;
    margin: auto;
    margin-top: $metric;
    margin-bottom: $metric;
    padding: $metric * 2;
    svg {
      height: 2 * $font_size;
    }
  }

  h2 {
    margin-bottom: 2 * $metric;
    width: $metric * 40;
  }
  h3 {
    margin-top: 1 * $metric;
  }
  input {
    text-align: center;
  }

  button,
  input {
    margin: $metric;
  }
  button {
    background-color: $purple;
    color: $white;
  }

  .joinFaded {
    background-color: $darkgrey;
    cursor: default;
    // box-shadow: 0 $border_size rgb(172, 172, 172);
  }
  .joinFaded:active {
    transform: none;
  }

  .changeCamera {
    border: solid $border_size $darkgrey;
    color: $black;
    background-color: transparent;
    font-size: $font_size * 0.95;
    padding: $metric 2 * $metric;
    margin-top: 0;
  }

  .close {
    background-color: $red;
    border-color: $red;
    color: $white;
  }
  .top {
    width: 100%;
    text-align: left;
  }
  .newPhoto {
    position: absolute;
    transform: translate($metric * 26, $metric);
    border-radius: 5 * $metric;
    color: white;
    background-color: $red;
    outline: 0;
    border: 0;
    text-align: center;
    align-items: center;
    display: flex;
    font-size: 2 * $metric;
    padding: $metric/2;
    z-index: 10;

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .userCamera {
    height: $metric * 30;
    width: $metric * 30;
    border-radius: $metric * 30;
    margin: 0 auto 0 auto;
    background-color: rgb(196, 196, 196);
    border: $metric $white solid;
  }
  .cameraOption {
    margin: 2 * $metric;
    width: 10 * $metric p {
      width: 12rem;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }

  .userCameraPreview {
    border-radius: 120px;
  }
  .cameraOption {
    cursor: pointer;
  }
  .imagePreview {
    transform: rotateY(180deg);
  }
}

//  __          __   _ _   _
//  \ \        / /  (_) | (_)
//   \ \  /\  / /_ _ _| |_ _ _ __   __ _
//    \ \/  \/ / _` | | __| | '_ \ / _` |
//     \  /\  / (_| | | |_| | | | | (_| |
//      \/  \/ \__,_|_|\__|_|_| |_|\__, |
//                                  __/ |
//                                 |___/

.waiting {
}

//   _           _     _
//  | |         | |   | |
//  | |     ___ | |__ | |__  _   _
//  | |    / _ \| '_ \| '_ \| | | |
//  | |___| (_) | |_) | |_) | |_| |
//  |______\___/|_.__/|_.__/ \__, |
//                            __/ |
//                           |___/

.lobby {
  .gameLogo {
    border-radius: 2em;
    height: 8em;
    margin: auto;
  }
}

//    _____                      _____
//   / ____|                    |  __ \
//  | |  __  __ _ _ __ ___   ___| |__) |___   ___  _ __ ___
//  | | |_ |/ _` | '_ ` _ \ / _ \  _  // _ \ / _ \| '_ ` _ \
//  | |__| | (_| | | | | | |  __/ | \ \ (_) | (_) | | | | | |
//   \_____|\__,_|_| |_| |_|\___|_|  \_\___/ \___/|_| |_| |_|

.gameRoom {
  display: flex;
  flex: 1;
  min-height: calc(100% - 4rem);
  overflow: auto;
  background-position: center;
  background-repeat: none;
  background-size: cover;
  padding: $metric;
  padding-bottom: $metric * 7;
  color: white;
  font-weight: normal;
  font-size: 1.5 * $font_size;

  .ultrawide{
    flex: 2 !important;
  }

  .mainUsers {
    // flex-grow: 1;
    // overflow: auto;
    // margin: $metric;
    // padding-top: 2.24 * $metric;
    // padding-bottom: 6 * $metric;
    height: calc(100vh - 12rem);
    width: 100%;
    overflow: auto;
    text-align: center;
  }

  .mainUsersGameRoom{
    padding: $metric;
  }

  .videosOff {
    color: $white;
    font-size: 0.9 * $font_size;
    margin-top: 1 * $metric;
    margin-left: 2 * $metric;
  }
  .progressContainer {
    align-self: center;
  }
  .loadingLogo {
    height: $font_size * 10;
    margin: $metric * 3;
  }

  .progressContainer {
    margin: auto;
  }

  .inprogress {
    padding-bottom: $font_size * 10;
    padding-top: $font_size * 2;
    align-self: center;
    text-align: center;
    font-size: 2 * $font_size;
  }
  .roundScore {
    color: white;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;

    font-size: 2.2 * $font_size;
    transform: translate(0, -20%);
    white-space: nowrap;
    overflow: hidden !important;
  }

  .gameName {
    font-size: $font_size * 1;
    font-weight: normal;
    // box-shadow: 0 $border_size $black;
    background-color: $white;
    width: fit-content;
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $metric/2 $metric * 2 $metric/2 $metric * 2;
    border-radius: 4 * $metric;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-left: 2 * $metric;
    img {
      height: 4.9 * $metric;
      margin-right: $metric;
    }
  }

  .g1 {
    color: $black;
    .afterTitle {
      width: calc(100% - 1.5rem);
      height: 2.5 * $metric;
      background-color: $white;
      margin: auto;
      border-radius: $metric;
      border-top-left-radius: $metric * 2;
      border-top-right-radius: $metric * 2;
      border-bottom: $border_size $darkshadow solid;
    }
    border-radius: 3 * $metric;
    margin: $metric;
    flex: 1;
    .nextGame {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      margin: auto;
      max-width: 70%;
      background-color: $white;
      padding: 2 * $metric 4 * $metric;
      padding-bottom: 0;
      border-top-left-radius: 3 * $metric;
      border-top-right-radius: 3 * $metric;
      img {
        height: $font_size * 2;
        max-width: $font_size * 8;
        object-fit: contain;
      }
      h2 {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: 1.3 * $font_size;
      }
    }
    button {
      background-color: $purple;
      color: $white;
      z-index: 0;
    }
    .instructions {
      max-width: 65 * $metric;
      margin: auto;
    }

    .practiceB {
      text-align: center;
      border-radius: 3 * $metric;
      .instructionContainer {
        background-color: $white;
        border-bottom: $border_size $darkshadow solid;
        padding: 2 * $metric 2 * $metric;
        margin: $metric;
        border-radius: 3 * $metric;
        font-size: $font_size * 1.1;
      }

      h3 {
        color: $purple;
      }
    }
    .howToPlay {
      height: 100%;

      iframe {
        height: calc(100vh - 16rem);
        width: 100%;
      }
    }
  }

  .gameStartingTimer {
    z-index: 2;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.63);
    div {
      margin: auto;
    }
    h1 {
      font-size: $font_size * 15 !important;
    }
  }

  .nextGame {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $metric;
    width: fit-content;
    margin: auto;
    background-color: $white;
    border-top-left-radius: 3 * $metric;
    border-top-right-radius: 3 * $metric;
    flex: 1;
    h3 {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    p {
      font-size: $font_size * 0.9;
      margin-bottom: $metric;
    }
    img {
      height: $metric * 6;

      max-width: $font_size * 8;
      object-fit: contain;

      margin-right: $metric;
    }
  }
  .gameScoreContainer {
    max-width: $metric * 60;
    min-width: $metric * 50;
    margin-left: $metric;
    margin-right: $metric;

    .countdownTimer {
      margin: auto;
      margin-top: $metric;
    }

    h1 {
      font-size: 2.5 * $font_size;
    }

    .gameScores {
      min-width: $metric * 30;
      max-width: $metric * 55;
      color: BLACK;
      text-shadow: NONE;
      line-height: 1.8 * $font_size;
      font-size: 1.7 * $font_size;
      text-align: center;
      width: 100%;

      border-radius: $metric * 2;
      display: inline-block;
      margin-top: 2 * $metric;
      .afterTitle {
        width: 100%;
        height: 2.5 * $metric;
        background-color: $white;
        max-width: 57 * $metric;
        margin: auto;
        border-radius: $metric;
        border-top-left-radius: $metric * 2;
        border-top-right-radius: $metric * 2;
        border-bottom: $border_size $darkshadow solid;
      }

      .nextGame {
        padding: $metric * 1.5 $metric * 2;
        padding-bottom: 0;
        margin: auto;
        border-bottom: none;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        max-width: 80%;
        img {
          height: $font_size * 2;
          max-width: $font_size * 8;
          object-fit: contain;
        }
      }
      .addPoints {
        font-weight: normal;
        font-size: 1 * $font_size;
        vertical-align: bottom;
        padding: $metric/2;
        border-radius: $metric;
        color: rgba(0, 0, 0, 0.527);
        margin-left: $metric;
      }

      h1 {
        margin: 0;
        text-align: left;
        font-size: $font_size * 1.8;
      }

      .scores {
        max-width: 60 * $metric;
        margin: auto;
      }
      .row {
        display: flex;
        flex-direction: row;
        color: $shadow;
        background-color: $white;
        padding: 1.5 * $metric 2 * $metric;
        border-radius: 2 * $metric;
        margin: $metric 0;
        color: $black;
        border-bottom: $border_size solid $darkshadow;
        font-size: 1.5 * $font_size;
        .position {
          flex: 6;
          color: rgb(189, 189, 189);
          text-align: left;
        }
        .column {
          text-align: left;
          flex: 8;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
        .gameScore {
          flex: 2;
          text-align: center;
          margin-right: 2 * $metric;
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            display: inline-block;
            height: $font_size * 1.4;
            margin-right: $metric/2;
            color: $yellow;
          }
        }
        .score {
          text-align: right;
          svg {
            display: inline-block;
            height: $font_size;
            margin-right: $metric/2;
            color: $yellow;
          }
        }
      }

      .pos0 {
        border-color: $gold;
        .gameScore {
          color: $gold;
        }
        .addPoints {
          background-color: $gold;
          color: rgba(255, 255, 255, 0.884);
        }
      }
      .pos1 {
        border-color: $silver;
        .gameScore {
          color: $silver;
        }
        .addPoints {
          background-color: $silver;
          color: rgba(255, 255, 255, 0.884);
        }
      }
      .pos2 {
        border-color: $bronze;
        .gameScore {
          color: $bronze;
        }
        .addPoints {
          background-color: $bronze;
          color: rgba(255, 255, 255, 0.884);
        }
      }
    }
  }

  .headerC {
    display: flex;
    align-items: center;
    background-color: $white;
    width: fit-content;
    padding: 1.5 * $metric 2.5 * $metric;
    margin: $metric auto;
    border-radius: 3 * $metric;
    border-bottom: $border_size $darkshadow solid;
    color: $black;
    h3 {
      margin-right: 2 * $metric;
      color: $black !important;
    }
    button {
      background-color: $purple;
    }
  }

  .container {
    flex: 1;
    text-align: left;
    margin: $metric;
    height: calc(100% - 1rem);
    color: black;

    .mainHeading {
      display: flex;
      vertical-align: top;
      align-items: center;

      color: $white;
      .mainHeadingInner {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        h1 {
          flex: 1;
          font-size: $font_size * 2;
          margin-right: $metric;
          text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
            #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
          -webkit-font-smoothing: antialiased;
        }
      }
    }

    h1 {
      text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
        #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
      -webkit-font-smoothing: antialiased;
      color: $white;
      text-align: center;
    }

    h1 {
      margin: $metric;
      margin-bottom: $metric;
      font-size: $font_size * 2;
      display: inline-block;
    }
    .userContainer {
      // margin: $metric;
      margin-top: 1 * $metric;
    }
  }

  iframe {
    flex: 1;
    border-width: 0;
    margin: $metric;
    max-height: calc(100vh - 4rem);
  }
}

//  __  __
// |  \/  |
// | \  / | __ _ _ __
// | |\/| |/ _` | '_ \
// | |  | | (_| | |_) |
// |_|  |_|\__,_| .__/
//              | |
//              |_|

.map {
  display: flex;
  text-align: left;
  padding: $metric;
  overflow: hidden;
  position: relative;
  max-height: 100%;

  .videoIssues {
    margin: 1 * $metric 2 * $metric;
    max-width: 40 * $metric;
    font-size: 0.8 * $font_size;
    color: $white;
    padding-bottom: 6*$metric;
  }

  .mainCenter {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    max-height: 100%;
    .pp {
      margin: 0 $metric;
    }
    .mainHeading {
      display: flex;
      vertical-align: top;
      margin: $metric;
      align-items: center;

      h1 {
        flex: 1;
        color: white;
      }
      .mainHeadingInner {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        height: 2.9rem;
        button {
          margin-left: 2 * $metric;
        }
      }
    }

    .mainUsers {
      // flex-grow: 1;
      // overflow: auto;
      // margin: $metric;
      // padding-top: 2.24 * $metric;
      // padding-bottom: 6 * $metric;
      height: calc(100vh - 12rem);
      width: 100%;
      overflow: auto;
      text-align: center;
    }
  }

 

  .nextGame {
    margin: $metric;
    width: 42 * $metric;
    

    .voted{
      // border: $metric/1 $yellow solid !important;
      // box-shadow: 0 $border_size/2 $black;
      // transform: translateY($border_size/2);
      &:before {
        display: grid;
        position: absolute;
        content: '✅';
        opacity: 1 !important;
        top: -40%;
        left: calc(-50% + 0.5rem);
        width: 100%;
        height: 100%;
        place-items: center;
        font-size: $font_size*2;
        // background: rgba(255,255,255,0.5);
        border-radius: 2 * $metric;
        z-index:2;
      }
    }

    .nextGameGame:hover {
      // box-shadow: 0 $border_size/4 $black;
      // transform: translateY($border_size/2);
      transform: scale(1.01);
    }

    .nextGameGame {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: $metric $metric*2 $metric $metric * 2;
      margin: $metric 0;
      border-radius: 3 * $metric;
      color: black;
      //border: $metric/1 $white solid;
      background-position: bottom;
      background-size: cover;
      cursor: pointer;
      position:relative;
      transition: all 0.5s;
      -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    // box-shadow: 0 $border_size $black;
    // transition: all 0.05s linear;
    // border: $border_size*2 rgb(255, 255, 255) solid;
    transition: 0.25s;
      img{
        max-width: 5*$font_size;
      }
      h3{
        font-size: $font_size*1.5;
      }
      .count{
        position: absolute;
        display: grid;
        align-items: center;
        border-top-right-radius: 2 * $metric;
        border-bottom-right-radius: 2 * $metric;
        right: 0;
        min-width: $font_size*2;
        padding: 0 $metric/2;
        color: #8e7eff;
        background: white;
        height: calc(100%);
        vertical-align: center;
        text-align: center;
        text-shadow: none !important;
      }
      &:hover:before {
        // opacity: 1;
        
      }
      &:before{
        // display: grid;
        // position: absolute;
        // content: '✅';
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;
        // place-items: center;
        // font-size: $font_size*2;
        // background: rgba(255,255,255,0.5);
        // opacity: 0;
        // transition: all 0.5s;
     

      }
    }
    p {
      font-size: $font_size * 1;
      margin-bottom: $metric;
    }
    img {
      height: $metric * 10;
      max-width: $metric * 28;

      object-fit: contain;
      margin: auto;
      margin-right: $metric;
    }
    h3 {
      flex: 1;
      font-size: 1.65 * $font_size;
      color: white;
      text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
        #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
      -webkit-font-smoothing: antialiased;
    }
  }
}

//  _____          _ _
// |  __ \        | (_)
// | |__) |__   __| |_ _   _ _ __ ___
// |  ___/ _ \ / _` | | | | | '_ ` _ \
// | |  | (_) | (_| | | |_| | | | | | |
// |_|   \___/ \__,_|_|\__,_|_| |_| |_|

.podiumRestart{
  position: absolute;
  right: $metric;
  top: $metric;
}


.overTop {
  background-color: $white;
  border-radius: 4 * $metric;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: fit-content;
  margin: auto;
  padding: $metric 2 * $metric;
  font-size: 1.4 * $font-size;
  margin-top: 4 * $metric;
  img {
    height: 1.4 * $font_size;
    width: fit-content;
    margin-right: $metric;
  }
}

.overBottom {
  color: $white;
  margin: $metric * 2 0;
}

.podium {
  display: flex;
  text-align: left;
  background-image: url(https://images.mixronline.com/images/2021/07/21/Confetti.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .mainCenter {
    flex: 1;
    text-align: center;
    h1 {
      margin-bottom: 2 * $metric;
    }
  }
  .overContainer {
    display: grid;
    grid-template-columns: 32vw auto 32vw;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    .restContainer {
      height: 100vh;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;

      div.user {
      }
    }
  }

  .podiumContainer {
    background-image: url(https://images.mixronline.com/images/2021/07/30/podium-13.png);
    background-size: contain;
    background-repeat: no-repeat;

    background-position: bottom;

    width: 72vh;
    height: 48vh;
    margin: $metric * 4 auto $metric;
    display: grid;
    grid-template-columns: 33.333% 33.3333% 33.333%;
    grid-template-rows: 50% 9% 11%;

    .user {
      align-self: flex-end;
    }
    .position0 {
      grid-area: 1 / 2 / 1 / 2;
      width: 24vh !important;
      height: 24vh !important;
      // transform: translateX(-1vh);
      margin: 0 auto;
    }

    .position1 {
      grid-area: 1 / 1 / 3 / 2;
      width: 21vh !important;
      height: 21vh !important;
      margin: 0 auto;
    }

    .position2 {
      grid-area: 1 / 3 / 4 / 4;
      width: 21vh !important;
      height: 21vh !important;
      margin: 0 auto;
    }
  }

  .podiumContainer2 {
    background-image: url(https://images.mixronline.com/images/2021/07/30/podium-13.png);
    background-size: contain;
    background-repeat: no-repeat;

    background-position: bottom;
    width: 33vw;
    height: 22vw;
    margin: $metric * 8 auto $metric * 2;
    display: grid;
    grid-template-columns: 33.333% 33.3333% 33.333%;
    grid-template-rows: 50% 9% 11%;

    .user {
      align-self: flex-end;
    }
    .position0 {
      grid-area: 1 / 2 / 1 / 2;
      width: 12vw !important;
      height: 12vw !important;
      transform: translateX(-0.5vw);
    }

    .position1 {
      grid-area: 1 / 1 / 3 / 2;
      width: 11vw !important;
      height: 11vw !important;
    }

    .position2 {
      grid-area: 1 / 3 / 4 / 4;
      width: 11vw !important;
      height: 11vw !important;
    }
  }
}

//   _____                                   ____  __ _         _____      _           _
//  / ____|                                 / /  \/  (_)       / ____|    | |         | |
// | |     __ _ _ __ ___   ___ _ __ __ _   / /| \  / |_  ___  | (___   ___| | ___  ___| |_
// | |    / _` | '_ ` _ \ / _ \ '__/ _` | / / | |\/| | |/ __|  \___ \ / _ \ |/ _ \/ __| __|
// | |___| (_| | | | | | |  __/ | | (_| |/ /  | |  | | | (__   ____) |  __/ |  __/ (__| |_
//  \_____\__,_|_| |_| |_|\___|_|  \__,_/_/   |_|  |_|_|\___| |_____/ \___|_|\___|\___|\__|

.dailySettings {
  max-width: $font_size * 25;
  padding: 2 * $metric;

  .wrapper {
    display: grid;
    grid-template-columns: 100px 100px 100px;
    grid-gap: 10px;
  }

  svg {
    height: 1.5 * $font_size;
    margin-right: $metric;
  }

  h2 {
    margin: 2 * $metric 0;
  }
  .selected {
    background-color: $purple;
    color: $white;
  }

  .selectedCamera {
    background-color: $purple;
    color: $white;
  }

  .cameraOption {
    display: flex;
    flex-direction: row;
    margin: $metric/2;
    padding: $metric/2;
    width: 80px;
    align-items: center;
    border-radius: $metric;
    cursor: pointer;
    video {
      border-radius: 4 * $font_size;
      margin-right: $metric;
    }
  }

  p {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    flex: 1;
  }

  .micOption {
    display: flex;
    flex-direction: row;
    margin: $metric/2;
    padding: $metric/2;
    align-items: center;
    border-radius: $metric;
    cursor: pointer;
  }

  button {
    margin: $metric;
    background-color: $purple;
    color: $white;
  }
  .cancel {
    background-color: $red;
  }

  .buttons {
    text-align: right;
  }
}

//               _           _
//      /\      | |         (_)
//     /  \   __| |_ __ ___  _ _ __
//    / /\ \ / _` | '_ ` _ \| | '_ \
//   / ____ \ (_| | | | | | | | | | |
//  /_/    \_\__,_|_| |_| |_|_|_| |_|

.admin_login_button {
  margin: $metric auto;
}

//  _____  _           _        _                 _   _
// |  __ \| |         | |      | |               | | | |
// | |__) | |__   ___ | |_ ___ | |__   ___   ___ | |_| |__
// |  ___/| '_ \ / _ \| __/ _ \| '_ \ / _ \ / _ \| __| '_ \
// | |    | | | | (_) | || (_) | |_) | (_) | (_) | |_| | | |
// |_|    |_| |_|\___/ \__\___/|_.__/ \___/ \___/ \__|_| |_|

.photobooth_wrapper {
  display: grid;

  height: auto;
  width: fit-content;
  margin: auto;
  position: relative;
  z-index: 0;
  h1 {
    position: absolute;
    z-index: 6;
    width: 540px;
    text-shadow: #000 0px 0px 2px, #000 0px 0px 2px, #000 0px 0px 2px,
      #000 0px 0px 2px, #000 0px 0px 2px, #000 0px 0px 2px;
    -webkit-font-smoothing: antialiased;
    overflow: hidden !important;

    font-size: 3.75 * $font_size;
    padding-top: 0.9 * $font_size;
  }
  .winnerPhotoboothTop {
    position: absolute;
    z-index: 6;
    width: 540px;
    font-size: 1.2 * $font_size;

    text-shadow: #000 0px 0px 2px, #000 0px 0px 2px, #000 0px 0px 2px,
      #000 0px 0px 2px, #000 0px 0px 2px, #000 0px 0px 2px;
    -webkit-font-smoothing: antialiased;
  }

  .photobooth_container {
    z-index: 5;
    display: grid;
    justify-content: center;

    canvas {
      position: absolute;
      z-index: 10;
    }

    .photobooth_backdrop {
      position: relative;
      height: 540px;
      width: 540px;
      border-radius: $metric;

      .userContainer {
        height: inherit;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .user {
        }
      }

      .user:nth-of-type(1) {
        position: absolute;

        z-index: -1;
        object-fit: cover;
        .userImage {
          height: 100%;
          width: 100%;
          object-fit: contain;
          border: 0;
          border-radius: 0;
        }
        .status,
        .audio,
        span {
          display: none !important;
        }
        //[236, 26, 385, 289]
        //[0, 208, 305, 229], [251, 82, 300, 225], [494, 215, 286, 215],
      }

      .user {
        margin: 0;
        padding: 0;

        h2 {
          display: none !important;
        }

        .userImage {
        }
      }
    }
  }
}

//   _____                  _      _                       _______ _
//  / ____|                | |    | |                     |__   __(_)
//  | |     ___  _   _ _ __ | |_ __| | _____      ___ __      | |   _ _ __ ___   ___ _ __
//  | |    / _ \| | | | '_ \| __/ _` |/ _ \ \ /\ / / '_ \     | |  | | '_ ` _ \ / _ \ '__|
//  | |___| (_) | |_| | | | | || (_| | (_) \ V  V /| | | |    | |  | | | | | | |  __/ |
//   \_____\___/ \__,_|_| |_|\__\__,_|\___/ \_/\_/ |_| |_|    |_|  |_|_| |_| |_|\___|_|

.countdownTimer {
  position: relative;
  // box-shadow: 0 $border_size $black;
  font-size: $font_size * 1.17;
  font-weight: normal;
  margin-left: 2 * $metric;
  background-color: $white;
  width: fit-content;
  color: $black;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4 * $metric;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-shadow: none;

  .text {
    margin-right: 1 * $metric;
    margin-left: 2 * $metric;
  }

  .seconds {
    min-width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;

    .secondsSpinner {
      width: 2rem;
      height: 2rem;
      border: 0.4rem solid;
      border-radius: 50%;
      align-items: center;
      border: 0.4rem solid $white;
      border-top: 0.4rem solid $purple;
      position: absolute;
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    .secondsText {
      text-align: center;
      color: $black;
      display: flex;
      align-items: center;
      position: absolute;
      background-color: $white;
      width: 2rem;
      height: 2rem;
      border: 0.4rem solid $white;
      border-radius: 50%;
      align-items: center;
      div {
        width: 100%;
      }
    }
  }
}

.loadingLogo {
  // animation-name: spin;
  // animation-duration: 2000ms;
  // animation-iteration-count: infinite;
  // animation-timing-function: linear;
  // margin-bottom: $metric * 4;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.landingScreen {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  div {
    margin: auto;
    text-align: center;
    background-color: $white;
    padding: 4 * $metric;
    border-radius: 4 * $metric;
    width: 640px;
    img {
      height: 10 * $metric;
      margin-bottom: $metric * 2;
    }
    h1 {
      margin-bottom: 2 * $metric;
    }
    p {
      margin-bottom: 2 * $metric;
    }
    iframe {
      border: none;
      width: 100%;
      height: calc(100vh - 25rem);
    }
  }
}

//  _____            _     _                         _
// |  __ \          | |   | |                       | |
// | |  | | __ _ ___| |__ | |__   ___   __ _ _ __ __| |
// | |  | |/ _` / __| '_ \| '_ \ / _ \ / _` | '__/ _` |
// | |__| | (_| \__ \ | | | |_) | (_) | (_| | | | (_| |
// |_____/ \__,_|___/_| |_|_.__/ \___/ \__,_|_|  \__,_|

.seasonSelect{
  padding: $metric/2 $metric;
  border-radius: $metric*2;
  margin: $metric;
}


.dashboard {
  display: flex;
  height: 100vh;
  flex-direction: column;

  .hero,
  .heroContainer {
    overflow: hidden;
    overflow-y: auto;
    padding: $metric;
    div {
      a {
        list-style-type: none;
        font-size: $font_size * 2;
      }
    }
  }

  header {
    display: flex;
    place-content: space-between;
    padding: $metric $metric * 1.5;
    place-items: center;
    border-bottom: $border_size solid $darkgrey;
    background: $white;
    color: $black;
    background-color: $white;
    height: $metric * 5;

    button {
      background-color: $purple;
      color: $white;
      display: flex;
      align-items: center;
      padding: $metric 2 * $metric;
      font-size: $font_size;
      img {
        margin-right: $metric;
      }
    }

    div {
      height: 100%;
      display: flex;
      align-items: center;
      .logo {
        height: $metric * 5;
        display: inline-block;
      }
      h1 {
        font-size: 2 * $font_size;
        margin-left: $metric;
      }
    }

    h1 {
      font-size: $font_size * 1.5;

      text-align: left;
    }
    .profile {
      display: flex;
      place-content: space-between;
      place-items: center;

      border-radius: $metric * 5;
      background-color: $purple;
      color: $white;
      text-align: right;

      .div {
        background-color: transparent;
      }

      svg {
        height: 0.8rem;
        vertical-align: middle;
        margin-left: $metric/2;
        text-align: right;
      }

      span {
        display: block;
        text-align: right;
        margin: 0 $metric * 1.5 0 $metric * 2;
        font-weight: normal;
      }
      span:nth-of-type(1) {
        font-size: $font_size * 1.2;
      }
      span:nth-of-type(2) {
        text-decoration: underline;
      }
      .profileImageContainer {
        height: $metric * 5.5;
        width: $metric * 5.5;

        border-radius: 100%;
      }
      .profileImage {
        width: calc(100%);
        height: calc(100%);
        border-radius: 150px;

        border: 4px $white solid;
      }
    }
  }

  > h1 {
    color: white;
  }
  .hero {
    background-color: $white;
  }

  .supported_browser {
    img {
      height: 20vh;
    }
  }

  .heroContainer {
    flex-grow: 1;
    display: flex;
    background: $purple;
    .leaderboard {
      padding: 0;
      margin: 0;
      width: calc(100% - 2rem);
      height: calc(100% - 0.5rem);
      background-image: url(https://images.mixronline.com/images/2021/12/18/leaderboardBg.png);
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
      overflow: auto;
      .cup {
        height: $font_size * 4;
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        span {
          text-align: left;
          margin-right: $metric;
        }
        select {
          border: solid $darkgrey $border_size;
          font-size: $font_size * 0.9;
          padding: $font_size * 0.1 $font_size * 0.3;
          font-family: "OpenSansB";
          border-radius: $metric;
        }
      }
      .officeChampionTitle {
        background-color: transparent;
        display: flex;
        width: fit-content;
        align-items: center;
        margin: auto;
        margin-bottom: $metric;
        img {
          height: 3 * $font_size;
        }
        h1 {
          font-size: 3 * $font_size;
          width: fit-content;
          margin: 0 $metric;
        }
      }
      .champion {
        margin: $metric auto;
        max-width: 55 * $metric;
        border: solid $border_size $darkgrey;
        background-color: $white;
        padding: $metric/2 $metric;
        text-align: left;
        display: flex;
        align-items: center;
        border-radius: 3 * $metric;
        .trophiesL {
          display: flex;
          align-items: center;
          font-size: 1.2 * $font_size;

          text-align: left;

          font-size: 1.5 * $font_size;
          margin-left: auto;
          display: flex;
          align-items: center;
          img {
            height: $font_size * 1.5;
            width: auto;
            border-radius: 0;
          }
        }

        .index {
          color: #b7b7b7;

          margin: 0 $metric;
          text-align: center;
          font-size: 1.2 * $font_size;
        }
        .name {
          font-size: 1.2 * $font_size;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
        img {
          height: 6 * $metric;
          width: 6 * $metric;
          border-radius: 50%;
          margin-right: $metric;
        }
      }

      .championsPodium {
        background-image: url(https://images.mixronline.com/images/2021/08/03/podiumTopP-3.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 65 * $metric;
        height: 42 * $metric;
        display: grid;
        grid-template-columns: 33.333% 33.3333% 33.333%;
        grid-template-rows: 50% 9% 11%;
        margin: 4 * $metric auto 2 * $metric;
      }

      .pc0 {
        grid-area: 1 / 2 / 1 / 2;
        z-index: 2;
      }

      .pc1 {
        grid-area: 1 / 1 / 3 / 2;
      }

      .pc2 {
        grid-area: 1 / 3 / 4 / 4;
      }

      .podiumChamp {
        align-self: flex-end;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        position: relative;
        .userImage {
          height: 20 * $metric;
          width: 20 * $metric;
          border-radius: 50%;
          margin: 0 auto;

          transform: translateY(2 * $metric);
        }
        svg {
          color: $yellow;
          position: absolute;
          width: 6.5 * $metric;
          top: -1.25 * $metric;
          left: -1.5 * $metric;
          transform: rotate(-35deg);
          stroke: $white;
          stroke-width: 20;
          // background-color: $white;
          padding-left: $metric/2;
        }
        div {
          margin: 0 auto;
          .name {
            margin: 0 auto;
            width: fit-content;
            padding: $metric/4 $metric;
            border-radius: 2 * $metric;
            font-size: 1 * $font_size;
            background-color: $white;
            max-width: 90%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            transform: translateY(-0.5 * $metric);
            box-shadow: 0 $border_size/1.5 $black;
          }

          span {
            display: flex;
            align-items: center;
            margin: 0 auto;
            padding: 0 $metric;
            width: fit-content;
            border-radius: 2 * $metric;
            font-size: 1 * $font_size;
            background-color: $white;
            img {
              height: 2 * $metric !important;
              width: auto !important;
              border-radius: 0;
              background-color: transparent !important;
              margin-right: $metric/2;
            }
          }
        }
      }

      .theChampion {
        background-color: transparent;
        display: flex;
        align-items: center;
        margin: auto;
        border: solid $border_size $yellow;
        background-color: $white;
        border-radius: 3 * $metric;
        max-width: 56 * $metric;
        text-align: left;
        position: relative;
        h3 {
          font-size: 2.25 * $font_size;
        }
        img {
          height: 15 * $metric;
          width: 15 * $metric;
          border-radius: 50%;
          margin-left: $metric * 1;
        }
        span {
          font-size: 1.5 * $font_size;
          display: flex;
          align-items: center;
          img {
            border-radius: 0;
            height: 1.5 * $font_size;
            width: auto;
            margin-right: $metric;
            margin-left: 0;
          }
        }
        svg {
          color: $yellow;
          position: absolute;
          width: 6.5 * $metric;
          top: -2.5 * $metric;
          left: -0.5 * $metric;
          transform: rotate(-35deg);
          stroke: $white;
          stroke-width: 20;
          background-color: $white;
          padding-left: $metric/2;
        }
      }
    }

    .hero {
      flex: 1;
      border-radius: 2 * $metric;
      padding: 0;
    }

    .menu {
      flex-basis: 35 * $metric;
      background: $purple;
      display: flex;
      flex-direction: column;
      margin-right: $metric;

      button {
        margin-bottom: $metric;
        width: 100%;
        text-align: left;

        background-color: #00000017;
        color: $white;
        border-radius: $metric * 2;

        img {
          padding-right: $metric * 1.2;
        }
      }

      .selected {
        button {
          background-color: #ffffff;
          color: $black;
        }
      }
      a {
        text-decoration: none;
        color: $white;
        font-size: $font_size * 1;
      }
      .bottom {
        text-align: left;
        margin: 0;
        margin-top: auto;
        button {
          font-size: $font_size;
          text-align: left;
          color: $white;
          width: fit-content;
          margin-left: 0;
          border: none;
          background-color: transparent;
          box-shadow: none;
          padding: $metric;
          display: flex;
          align-items: center;
          text-align: left;
          margin-bottom: 0;
        }
      }
    }
  }
}

.game-champions {
  overflow-y: hidden;

 

  .game-container {
    display: flex;
    flex-wrap: wrap;
    color: white;
    justify-content: center;
    margin-top: 1 * $metric;
  }
  img {
    max-height: 10 * $metric;
  }
  div {
    background-size: cover;
    background-position: bottom;
  }

  h1 {
    font-size: 2.5 * $font_size;
  }

  .gameLogoPlaceholder {
    height: $metric * 8;
    width: $metric * 10;
    margin-right: $metric;
  }

  .game {
    margin: $metric $metric;
    border-radius: $metric * 2 !important;
    width: 45 * $metric;

    height: 65 * $metric;
    overflow: visible;
    position: relative;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    .highscore {
      text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
        #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
      -webkit-font-smoothing: antialiased;
      font-size: $font_size * 1.5;
      background-color: #0000002d;
      width: fit-content;
      margin: auto;
      padding: $metric 2 * $metric;
      border-top-left-radius: $metric * 2;
      border-top-right-radius: $metric * 2;
    }
    .gameTitle {
      background-color: #00000075;
      margin: auto;
      padding: $metric 2 * $metric;
      border-radius: 2 * $metric;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin: $metric auto 0;
      display: flex;
      align-items: center;
      width: fit-content;
      max-width: calc(100% - 5rem);
      img {
        height: $metric * 8;
        margin-right: $metric;
      }
      h3 {
        font-size: $font_size * 1.5;
        text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
          #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
        -webkit-font-smoothing: antialiased;
        text-align: left;
      }
    }
    .scores {
      margin: $metric/2 0;
    }

    .champion-user {
      display: flex;
      align-items: center;
      margin: 0 $metric;
      border-radius: 0;
      padding: $metric;
      background-color: #00000075;
      border-radius: $metric * 2;
      font-size: 1.2 * $font_size;

      .name {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        text-align: left;
      }
      .index {
        width: $metric * 4;
      }
      img {
        height: $font_size * 2;
        width: $font_size * 2;
        border-radius: 50%;
        margin-right: $metric;
      }
      .score {
        display: flex;
        align-items: center;
        img {
          height: 1.2 * $font_size;
          width: auto;
          border-radius: 0;
          margin-right: $metric/2;
        }
      }
      .score {
        margin-left: auto;
        padding-left: $metric;
      }
    }

    .c0 {
      width: fit-content;
      margin: auto;
      border-radius: $metric * 2;
      width: calc(100% - 2rem);

      img {
        height: $font_size * 6;
        max-height: $font_size * 6;
        width: $font_size * 6;
      }
      div {
        margin: 0;
        overflow: hidden;
      }
      .score {
        padding-left: 0;
        margin: 0;
      }
      h4 {
        font-size: 1.5 * $font_size;
        text-align: left;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }

    // > .scores {
    //   display: flex;
    //   flex-direction: row;
    //   align-self: flex-end;
    //   margin: 0;
    //   width: 100%;
    //   border-radius: $metric * 3;
    //   background: transparent;

    // .champion-user:nth-of-type(1) {
    //   border-bottom-right-radius: 0;
    //   //border-top-right-radius: 0;
    //   margin-right: $metric/4;
    // }
    // .champion-user:nth-of-type(2) {
    //   border-bottom-left-radius: 0;
    //   //border-top-left-radius: 0;
    //   margin-left: $metric/4;
    // }
    // .champion-user {
    //   color: black;
    //   margin: $metric/2;
    //   position: relative;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-evenly;
    //   border-radius: $metric * 3;
    //   flex-basis: 50%;
    //   padding-top: $metric * 3;
    //   height: $metric * 12;
    //   * {
    //     font-weight: 50;
    //   }
    //   > img {
    //     position: absolute;
    //     top: -$metric * 2.5;
    //     left: $metric * 7.5;
    //     height: $metric * 5;
    //     width: $metric * 5;
    //   }
    //   .score {
    //     background: #8e7eff;
    //     color: white;
    //     display: inline-block;
    //     border-radius: $metric * 5;
    //     padding: $metric/3 $metric/3;
    //     display: flex;
    //     width: $font_size * 4.5;
    //     place-items: center;
    //     margin: 0 auto;
    //     justify-content: space-between;
    //     .hide {
    //       visibility: hidden;
    //     }
    //     img {
    //       height: $font_size * 1.2;
    //       width: $font_size * 1.2;
    //     }
    //   }
    // }
    // }
  }
}

.my-stats {
  padding: 2 * $metric;
  .userImg {
    height: 20 * $metric;
    width: 20 * $metric;
    border-radius: 50%;
  }
  h2 {
    font-size: 2.5 * $font_size;
  }
  .trophyS {
    display: flex;
    align-items: center;
    margin: $metric auto;
    width: fit-content;
    font-size: $font_size * 2;
    img {
      height: $font_size * 2;
      margin-right: $metric;
    }
  }
  div {
    background-size: cover;
    background-position: center;
  }
  .my-stats-games {
    display: flex;
    flex-wrap: wrap;
    color: white;
    overflow: hidden;
    justify-content: center;

    font-size: 1.5 * $font_size;

    h3 {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
        #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
      -webkit-font-smoothing: antialiased;
    }
    img {
      height: 10 * $metric;
    }

    .my-stats-game {
      margin: $metric;
      width: 35 * $metric;
      padding: $metric;
      border-radius: 1rem;
      .scores {
        border-radius: 1rem;
        color: $white;
        padding: $metric;
        margin-top: 0;
        display: flex;
        h4 {
          flex: 1;
        }
        span {
          font-size: $font_size;
          align-items: center;
          display: flex;
          width: fit-content;
          margin: auto;
        }
        img {
          height: $font_size * 2;
          margin-right: $metric;
        }
      }
    }
  }
}
.home{

  .pandt{
    color: $lightpurple;
    a{
      color: $lightpurple;
    }
  }

  text-align: left;
  margin: 2*$metric;

  button{
    color: $white;
    background-color: $purple;
    font-size: $font_size;

  }

  .homeText{
    max-width: 120*$metric;
    // margin: auto;
  }
.logoTitleParent{
// margin: auto;
// width: fit-content
}

.logoTitle{
  text-align: center;
  margin: auto;
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-top: $metric*2;
  
  h1{
    width: fit-content;
    text-align: left;
  }
  img{
    height: $metric*6;
    margin-right: $metric;
  }
}


  p,a{
    font-size: $font_size !important;
  }
  span{
    color: $purple;
  }


  a{
    color: $purple;
    cursor: pointer;
  }
  p{
    margin: 3*$metric 0;
  }

  .events {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: hidden;
    margin: $metric;
    // justify-content: center;
    border-bottom: $darkgrey $border_size solid;
    padding-bottom: $metric*2;

  }
  .white-text{
    color: $white;
  }

  .canJoin{
    border: $yellow $metric/2 solid !important;
  }

  .welcomeTop{
    border-bottom: $darkgrey $border_size solid;
    padding-bottom: $metric*2;
    margin-bottom: $metric*2;
  }

  .eventAdd{
    background-color: $lightpurple !important;
    border-color: $lightpurple !important;
    color: $lightpurple !important;
    cursor: pointer;
    height: 35.8*$metric;


    .ee{
      display: flex;
      align-items: center;
      min-height: $metric*17;
    }

    .dateStamp{
      background-color: $lightpurple !important;
      height:6*$metric;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    img{
      filter: grayscale(100%);
      display: none;
      border-radius: 0  !important;
      height: 12 * $metric !important;
      width: 12 * $metric !important;
      border-radius: 50%;
      margin: 0 auto !important;
      padding:0 0 !important;
      border: $white $metric/2 solid !important;
    }
    svg{
      border: $white $metric/2 solid !important;
      padding:0 0 !important;
      margin: 0 auto !important;
      border-radius: 0  !important;
      height: 12 * $metric !important;
      width: 12 * $metric !important;
        path{
        fill: $lightpurple !important;
      }
    }
  }
  .eventAdd:hover{
    background-color: $purple !important;
    border-color: $purple !important;
    color: $black !important;

    .dateStamp{
      background-color: $purple !important;
    }
    img{
      filter: grayscale(0%);
      display: block;
    }
    svg{
      display: none;
      path{
      fill: $purple !important;
    }
  }
  }

  .event {
    text-align: center;
    color: $black;
    background-color: $purple;
    border: $purple $metric/2 solid;
    margin: $metric  $metric;
    // padding: $metric * 2;
    border-radius: $metric * 2 !important;
    min-width: 25*$metric;
    max-width: 25*$metric;
    overflow: hidden;
    .joinAnimate{
      animation: shimmer 1s linear infinite alternate;
    }
    .scale{
      transform: scale(1.2);
    }
    .purple{
      color: $purple;
    }
    .cancel{
      background-color: $red;
    }
 
    button{
      font-size: $font_size*0.8;
      padding: $metric $metric*1.5;
    }

    .placeholder{
      height: $metric*3;
      width: 100%;
    }
    .details {
      text-align: left;
      color: $white;
      margin-bottom: $metric * 2;
      display: flex;
      justify-content: space-between;
      h4:nth-of-type(2) {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .historyPodium {
      background-image: url(https://images.mixronline.com/images/2021/07/30/podium-13.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      width: 36 * $metric;
      height: 24 * $metric;
      margin: auto;
      display: grid;
      grid-template-columns: 33.333% 33.3333% 33.333%;
      grid-template-rows: 50% 9% 11%;
    }

    .p0 {
      grid-area: 1 / 2 / 1 / 2;
    }

    .p1 {
      grid-area: 1 / 1 / 3 / 2;
    }

    .p2 {
      grid-area: 1 / 3 / 4 / 4;
    }
    .dateStamp{
      background-color: $purple;
      color: $white;
      padding: $metric;
    }

    .timeStamp{
        background-color: $white;
        padding: $metric 0 0;
    }
    .buttoins{
      background-color: $white;
      padding: $metric 0;
    }
    .person {
      align-self: flex-end;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      background-color: $white;
      padding:  $metric 0 $metric;
      h4{
        font-size: 0.85*$font_size;
      }
      img, svg {
        height: 10 * $metric;
        width: 10 * $metric;
        border-radius: 50%;
        margin: 0 auto;
        border: $white $metric/2 solid;
        // transform: translateY(1.75 * $metric);
      }
      .name {
        margin: 0 auto;
        width: fit-content;
        padding: $metric/4 $metric/2;
        border-radius: 2 * $metric;
        font-size: 0.8 * $font_size;
        background-color: $white;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        transform: translateY(-0.1 * $metric);
      }
    }
  }

}

.history {
  
  .events {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: hidden;
    margin: $metric;
    justify-content: center;
  }
  .white-text{
    color: $white;
  }
  .event {
    background-color: $purple;
    margin: $metric  $metric;
    padding: $metric * 2;
    border-radius: $metric * 2 !important;
    .details {
      text-align: left;
      color: $white;
      margin-bottom: $metric * 2;
      display: flex;
      justify-content: space-between;
      h4:nth-of-type(2) {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .historyPodium {
      background-image: url(https://images.mixronline.com/images/2021/07/30/podium-13.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      width: 36 * $metric;
      height: 24 * $metric;
      margin: auto;
      display: grid;
      grid-template-columns: 33.333% 33.3333% 33.333%;
      grid-template-rows: 50% 9% 11%;
    }

    .p0 {
      grid-area: 1 / 2 / 1 / 2;
    }

    .p1 {
      grid-area: 1 / 1 / 3 / 2;
    }

    .p2 {
      grid-area: 1 / 3 / 4 / 4;
    }

    .person {
      align-self: flex-end;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      img {
        height: 10 * $metric;
        width: 10 * $metric;
        border-radius: 50%;
        margin: 0 auto;
        border: $white $metric/2 solid;
        transform: translateY(1.75 * $metric);
      }
      .name {
        margin: 0 auto;
        width: fit-content;
        padding: $metric/4 $metric/2;
        border-radius: 2 * $metric;
        font-size: 0.8 * $font_size;
        background-color: $white;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        transform: translateY(-0.1 * $metric);
      }
    }
  }
}

.reoccuringPage{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  *{
    margin: $metric;
  }
  h2{
    color: $white;
  }
}

.auth_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .space {
    margin: $font_size * 3;
    color: white;
  }
  button {
    animation: login 0.05s ease-in;
    transform: scale(1.2);
    padding: $metric $metric * 3;
    transition: 0.5s;
  }
  @keyframes login {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }
}

.landingScreen {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  div {
    margin: auto;
    text-align: center;
    background-color: $white;
    padding: 4 * $metric;
    border-radius: 4 * $metric;
    width: 640px;
    img {
      height: 10 * $metric;
      margin-bottom: $metric * 2;
    }
    h1 {
      margin-bottom: 2 * $metric;
    }
    p {
      margin-bottom: 2 * $metric;
    }
    iframe {
      border: none;
      width: 100%;
      height: calc(100vh - 20rem);
    }
  }
}

.logoutModal {
  padding: $metric * 4;
  text-align: center;
  h3 {
    margin-bottom: 2 * $metric;
  }
  button {
    margin: $metric;
    color: $white;
    background-color: $purple;
  }
}

.contactus {
  padding: 4 * $metric;
  text-align: center;
  h1 {
    margin: $metric;
    font-size: 1.5rem !important;
  }
  button {
    margin: $metric;
    color: $white;
    background-color: $red;
  }
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  * {
    margin: auto;
  }
}

.noAdminDash {
  h1 {
    margin: $metric * 2;
  }
  h4 {
    margin: $metric * 2;
  }

  .adminUser {
    display: flex;
    width: fit-content;
    align-items: center;
    margin: $metric auto !important;
    img {
      height: $metric * 6;
      width: $metric * 6;
      border-radius: 50%;
      margin-right: $metric;
    }
  }
}

.manageBilling {
  .workEmailManage {
    padding: 2 * $metric;
    margin: $metric * 2;
    border-radius: 2 * $metric;
    border: $metric/3 $yellow solid;
    text-align: left;
    span {
      color: $purple;
    }
    h2 {
      margin: 1 * $metric 0;
    }
  }

  .newTeamMember {
    padding: $metric;
    border-radius: $metric;
    background-color: $darkgrey;
    margin: $metric;
    width: fit-content;
    display: inline-block;
  }

  .contactUs {
    margin-top: 4 * $metric;
    color: $shadow;
    u {
      cursor: pointer !important;
    }
  }

  h1 {
    margin: 3 * $metric $metric;
  }
  h3 {
    margin: $metric * 4 0 $metric * 2;
  }

  button {
    background-color: $purple;
    color: $white;
  }

  .close {
    margin: auto;
    width: fit-content;
    background-color: $red;
    font-size: $font_size;
    margin-top: $metric * 2;
  }

  .checkingValid {
    background-color: #c2c2c2 !important;
  }

  .addBillingAdmin {
    padding: 1 * $metric;
    max-width: 50 * $metric;
    border-radius: 2 * $metric;
    margin: 4 * $metric auto;
    background-color: $darkgrey;
    h3 {
      margin: $metric;
    }
    input,
    button,
    h1 {
      font-size: $font-size;
      padding: $metric 2 * $metric;
    }
    input {
      width: calc(100% - 3rem) !important;
    }
    .userListItem {
      padding: $metric;
      background-color: $white;
      text-align: left;
      border-radius: 2 * $metric;
      display: flex;
      align-items: center;
      margin: $metric;
      h1 {
        margin: 0;
        padding: 0;
      }
      button {
        margin-left: auto;
        margin-right: 0;
      }
    }
    .close {
      background-color: $red;
      font-size: $font_size;
      margin: $metric * 2;
    }
  }
}

.login {
  display: flex;
  align-items: center;
  height: 100vh;

  input {
    margin: $metric;
  }
  .red {
    color: $red;
  }
  .logoutButton {
    background-color: $red;
    color: $white;
    font-size: 1rem;
    margin-top: 4 * $metric;
  }

  div {
    width: fit-content;
    margin: auto;
    text-align: center;
    background-color: $white;
    border-radius: 4 * $metric;
    padding: $metric * 4;
    h1 {
      margin: 2 * $metric auto;
    }
    a,
    button {
      background-color: $purple;
      color: $white;
      margin: $metric auto;
      margin-top: 3 * $metric;
      font-size: 1.25 * $font_size;
      padding: $metric 2 * $metric;
      display: flex;
      align-items: center;
      text-decoration: none;
      border-radius: $metric * 4;
      img {
        height: 1.5 * $font_size;
        padding: 0.2 * $font_size;
        margin: 0;

        border-radius: 50%;
        margin-right: $metric/2;
      }
    }

    img {
      height: 6 * $metric;
      margin: $metric;
    }
    p {
      font-size: 0.9 * $font_size;
      color: $shadow;
      margin-top: 3 * $metric;
    }
  }

  .auth_container {
    height: auto;
    padding: 0;
    button {
      transform: scale(1);
    }
  }
}

.unsupportedBrowser {
  padding: $metric * 4 $metric * 2;
  text-align: center;
  width: 60 * $metric;
  max-width: 90vw;
  max-height: 85vh;
  overflow: auto;
  img {
    width: 40vh;
    height: auto;
  }
  .supported_browser {
    img {
      height: auto !important;
      width: 95% !important;
      margin: auto !important;
      border-radius: 25px !important;
    }
    a {
    }
  }

  button {
    background-color: $purple;
    color: white;
  }

  .supported_browser {
    img {
      width: 20vh;
      height: auto;
      cursor: pointer;
    }
    a {
      width: 10vh;
      height: auto;
      cursor: pointer;
    }
  }
}


.welcomeModal {
  padding: $metric * 2;
  text-align: center;
  width: 50 * $metric;
  max-width: 90vw;
  max-height: 85vh;
  overflow: auto;
  .pEnd {
    margin: 2 * $metric $metric;
    font-size: $font_size * 1.5;
  }

  .pOther {
    margin: 2 * $metric $metric;
    font-size: $font_size * 0.9;
  }
  .link {
    cursor: pointer;
  }
  .next,
  h1 {
    color: $purple;
  }
  img {
    width: 80%;
    max-width: 50 * $metric;
    margin: $metric auto;
  }
  button {
    padding: $metric 1.5 * $metric;
    font-size: $font_size;
    color: $white;
    background-color: $purple;
    img {
      width: auto;
      margin: 0;
      margin-right: $metric;
    }
  }
  * {
    margin: $metric;
  }

  p {
    span {
      margin: 0;
      color: $purple;
    }
  }

  .close {
    background-color: $red;
  }
}

.noStripe {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.74);
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  div {
    margin: auto;
    h2 {
      margin-bottom: $metric * 2;
    }
    button {
      background-color: $red;
      color: $white;
    }
  }
}

//  ____  _ _ _ _
// |  _ \(_) | (_)
// | |_) |_| | |_ _ __   __ _
// |  _ <| | | | | '_ \ / _` |
// | |_) | | | | | | | | (_| |
// |____/|_|_|_|_|_| |_|\__, |
//                       __/ |
//                      |___/

.billing_container {
  &.hide {
    display: none;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > a {
    color: black;
    font-size: $font_size !important;
    margin: $metric * 2;
  }
}
.billing {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    margin-top: $metric * 4 !important;
  }

  .hidden {
    display: none;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
    color: $black;
  }
  button {
    margin: auto;
    background-color: $purple;
    color: $white;
  }
  h1,
  h2,
  h3,
  h4 {
    margin: $metric auto;
    text-align: center;
    color: $black;
  }
  .ccr {
    color: $black;
    font-size: $font_size * 0.9;
    margin-top: $metric;
    margin-bottom: $metric * 4;
  }

  a {
    color: $black;
    font-size: $font_size * 1 !important;
  }
  .continue_container {
    margin: $metric * 3;
    .warning {
      margin: $metric * 2;
      color: $red;
    }
  }

  .billTypeSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto !important;
    width: fit-content;
    margin: $metric * 1 auto !important;
    color: $black;
    span {
      width: $metric * 20;
      text-align: center;
    }
    .left {
      text-align: right;
      padding-right: $metric;
    }
    .right {
      text-align: left;
      padding-left: $metric;
    }
  }

  .plans {
    margin: 2 * $metric 0 !important;
    margin-bottom: $metric;
    text-align: center;
    .selectedPlan {
      border: solid $border_size * 2 $yellow !important;
    }
    .container {
      display: inline-block;
      margin: $metric;
      padding: $metric * 2;
      border: solid $border_size * 2 transparent;
      border-radius: $metric * 2;
      width: $metric * 20;
      background-color: $white;
      color: $black;
      border: solid $border_size * 2 $darkgrey;
      cursor: pointer;
      position: relative;
      &:hover {
        animation: login 0.05s linear;
        transition: 0.5s;
        transform: scale(1.02);
      }
      @keyframes login {
        from {
          transform: scale(1);
        }
        to {
          transform: scale(1.02);
        }
      }
      .currentPlan {
        background-color: $purple;
        color: $white;
        padding: $metric;
        border-radius: 2 * $metric;
        position: absolute;
        top: -2 * $metric;
        right: -2 * $metric;
        font-size: $font_size * 0.9;
      }

      p {
        margin: 0;
      }

      .name {
        font-family: "OpenSansB";
        margin-bottom: $metric/2;
      }

      .size {
        font-family: "OpenSansB";
        font-size: $font_size * 1.2;
      }
      .users {
      }
      .price {
        font-family: "OpenSansB";
        color: $purple;
        font-size: $font_size * 3;
        line-height: $font_size * 3;
        margin: $metric * 2 0;
        span {
          font-size: $font_size * 1;
        }
      }
      .priceC {
        font-family: "OpenSansB";
        color: $purple;
        font-size: $font_size * 2;
        line-height: $font_size * 2;
        margin: $metric * 2 0;
        height: $font_size * 3;
      }
      .billedC {
        margin-top: $metric * 5.5;
      }

      .billed {
        margin-top: $metric * 4;
      }
    }
  }
}

.waitlist {
  color: $black !important;
  cursor: pointer;
  margin-top: $metric * 2;
  margin-bottom: $metric * 6;
}

//  _____  _
// |  __ \| |
// | |__) | |__   ___  _ __   ___
// |  ___/| '_ \ / _ \| '_ \ / _ \
// | |    | | | | (_) | | | |  __/
// |_|    |_| |_|\___/|_| |_|\___|

.phoneInstructions {
  text-align: center;
  .nextGame {
    img {
      height: 4rem;
      width: auto;
      margin: 0;
    }
    h2 {
      color: $white;
    }
  }

  .instructionContainer {
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem;
    background-color: $white;
    h3 {
      color: $purple;
    }
  }

  .headerC {
    h3 {
      color: $white;
      margin-bottom: 1rem;
    }
  }
}

.gameIframe {
  .timer {
    font-size: 1rem;
    line-height: 1.2rem;
    color: $white;
    text-align: center;
  }
  iframe {
    border: none;
  }
  .headerC {
    display: flex;
    flex-direction: row;
    height: 2rem;
    h3 {
      width: fit-content;
      color: $white;
    }
    button {
      height: 1.5rem;
      font-size: 1rem;
      padding: 0 $metric;
      margin-left: auto;
    }
  }
}

.icebreaker {
  background-color: $white !important;
  border-radius: $metric * 2;
  width: fit-content;
  z-index: 1;
  text-align: left;
  display: block !important;
  max-width: 70%;

  h3 {
    font-size: $font-size * 0.8;
    color: $purple;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  h4 {
    font-size: $font-size * 0.8;
    color: $black;
    margin-bottom: $metric/2;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
.gameStartingTimer {
  z-index: 10;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $white;
  background-color: rgba(0, 0, 0, 0.63);
  div {
    margin: auto;
  }
  h1 {
    font-size: $font_size * 15 !important;
  }
}

.waitingPhone {
  color: $white;
  display: flex;
  width: 100vw;
  align-items: center;
  text-align: center;
  padding-top: 5rem;
  h1 {
    text-align: center;
    width: 100%;
  }
}

.conenctPhone {
  padding: $metric/1.5 $metric * 2.5;
  font-size: $font_size;
  display: flex;
  align-items: center;
  text-align: left;

  font-size: $font_size * 0.8;
  svg {
    height: $font_size * 1.6;
    margin-right: $metric;
  }
}

.scan {
  max-width: 80vw;
  padding: 4 * $metric;
  h3 {
    text-align: center;
    margin-bottom: $metric * 4;
  }
  svg {
    margin-left: auto;
  }
}
.startMixrButtonPhone {
  display: none;
}

.modalInstructions {
  color: $black !important;
  text-align: center !important;
  margin: $metric * 2 !important;
  padding: $metric * 4 0 !important;

  .headerC {
    display: none !important;
  }

  h3 {
    margin-top: $metric * 2;
    color: $purple !important;
  }
  .closeModalButton {
    display: block !important;
    background-color: $purple;
    color: $white;
    margin: auto;
    margin-top: 2 * $metric;
  }
}

.g1 {
  .closeModalButton {
    display: none !important;
  }
}

.howTopPlayIcon {
  cursor: pointer;
}

@media screen and (orientation: portrait) {
  .timer {
    padding: $metric;
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  .gameIframe {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100vw;
    touch-action: none;
    overflow: hidden;
    overscroll-behavior-y: contain;
    iframe {
      width: 100vw;
      height: calc(
        100vh - 1.2rem
      ); /* Fallback for browsers that do not support Custom Properties */
      height: calc((var(--vh, 1vh) * 100) - 3rem);
    }

    .g33333 {
      height: calc(160vw);
    }
    .g66666 {
      height: calc(140vw);
    }
  }
}
@media screen and (orientation: landscape) {
  .gameIframe {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100vw;
    touch-action: none;
    overflow: hidden;
    overscroll-behavior-y: contain;
    iframe {
      width: 100vw;
      height: calc(
        100vh - 1.5rem
      ); /* Fallback for browsers that do not support Custom Properties */
    }
  }
}

//   _____                 _ _  __          ___     _ _   _
//  / ____|               | | | \ \        / (_)   | | | | |
// | (___  _ __ ___   __ _| | |  \ \  /\  / / _  __| | |_| |__
//  \___ \| '_ ` _ \ / _` | | |   \ \/  \/ / | |/ _` | __| '_ \
//  ____) | | | | | | (_| | | |    \  /\  /  | | (_| | |_| | | |
// |_____/|_| |_| |_|\__,_|_|_|     \/  \/   |_|\__,_|\__|_| |_|

.hamburger-react {
  display: none !important;
}

@media only screen and (max-width: 750px) {
  .restContainer {
    display: none;
  }
  .conenctPhone {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .menu {
    flex-basis: 35 * $metric;
    background: $purple !important;
    display: flex;
    flex-direction: column;
    margin-right: $metric;
    overflow: auto;
    position: fixed;
    right: 0;
    top: $font_size * 3.6;
    transform: translatex(150%);
    transition: all 0.1s linear;
    z-index: 1000;
    padding: 0.5rem;
    border-bottom-left-radius: 2 * $metric;
    color: $black !important;
    border-left: $darkgrey $border_size solid;
    border-bottom: $darkgrey $border_size solid;
    padding-left: $metric * 2 !important;
  }

  .openfalse {
    transform: translatex(150%);
  }
  .opentrue {
    transform: translatex(0);
  }

  .hamburger-react {
    display: flex !important;
  }

  .dashboard {
    padding-top: $metric * 7 !important;
    height: calc(100vh - 3.5rem);
    header {
      position: fixed;
      top: 0;
      width: calc(100% - 1.5rem);

      z-index: 100;
    }

    .hero {
      overflow-y: inherit !important;
    }
  }
  .startMixrButton {
    display: none !important;
  }

  .startMixrButtonPhone {
    display: block !important;
  }

  .dashboard .heroContainer .hero {

    height: calc(100vh - 4.5rem);

  }

}

.createNewSeasonModal{
  text-align: center;
  width: 80vw;
  max-width: 60*$metric;
  *{margin: $metric}
  .yes{
    color: $white;
    background-color: $purple;
  }
  .no{
    color: $white;
    background-color: $red;
  }
}



@media only screen and (max-width: 650px) {

  .championsPodium {
    width: 80vw !important;
    height: 55vw !important;
    .userImage {
      width: 27vw !important;
      height: 27vw !important;
    }
    .name {
      font-size: 0.8rem !important;
    }
  }
  h1 {
    font-size: 2rem !important;
  }
}

.waiting {
  margin-top: $font_size;
  .admins {
    h3 {
      margin: $font_size;
    }
    button {
      cursor: default;
      display: flex;
      margin: auto;
      img {
        margin-right: $font_size;
        height: $font_size * 2;
        border-radius: 100%;
      }
    }
  }
}

.user {
  &:hover {
    .giveControlContainer {
      visibility: visible;
      opacity: 1;
    }
  }
  .giveControlContainer {
    position: absolute;
    border-radius: 100%;
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.1s, opacity 0.1s linear;
    justify-content: center;
    align-items: center;
    z-index: 1;

    button {
      font-size: $font_size;
    }
  }
}

.gFloppy {
  background-color: #5FE371 !important;
}

.gGhost{
  background-color: #000000d5 !important;
}

.g2048 {
  background-color: #867661d5 !important;
}

.gBlockRain {
  background-color: #484848e5 !important;
}

.gHoop {
  background-color: #ca753fd9 !important;
}

.gRainbow {
  background-color: #8e6a7cde !important;
}

.gHardest {
  background-color: #2f3c88d7 !important;
}

.gCandy {
  background-color: #c894ffe5 !important;
}

.gFruit {
  background-color: #174f65de !important;
}

.mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  h3 {
    max-width: 80%;
    margin: $metric;
    color: white;
  }
}

.expired {
  color: $white;
  h1,
  h3 {
    margin: 2 * $metric 0;
  }
  button {
    margin: $metric;
  }
}

.loadingButton {
  text-align: left;
  font-size: 2 * $metric;
  padding: $metric 2 * $metric !important;
}

.loadingButtonEvent {
  width: 18 * $metric;
  text-align: center;
}

.shine {
  animation: shimmer 1s linear infinite alternate;
}
@keyframes shimmer {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
.scheduleMixr{

  max-width: 40*$metric !important;
  width: 80vw !important;
 
  td, th{
    border-radius: $metric/2;
  }

  .displayOnHomepage{
       display: flex;
       flex-direction: row;
       align-items: center;
       .cbox{
         flex: 1;
       }
       .homepage_msg{
         flex: 4;
         text-align: left;
       }
     }

  .whiteText{
 text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
    color: $white;
    margin: $metric auto !important; 

  }

  thead, .rdtCounters{
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
    #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
  -webkit-font-smoothing: antialiased;
  color: $white;
  }

  .rdtTimeToggle{
    font-size: $font_size*1.3;
    padding: $metric;
  }

  .rdtSwitch{
    cursor: pointer;
    background-color: $white;
    color: $black;
    text-shadow: none;
  }

  .rdtSwitch:hover{
    background-color: #ff0000 Im !important;
  }

  .rdtBtn:hover{
    background-color: transparent !important;
  }

  .rdtPrev{
    border-bottom: none !important;
  }

  .rdtPrev:hover{
    background-color: transparent !important;
  }

  .rdtNext{
    border-bottom: none !important;
  }

  .rdtNext:hover{
    background-color: transparent !important;
  }

.rdtCounters *{
  margin: 0 !important;
}

  .date{
    max-width: 40*$metric !important;
  }

    .rdtDays{
      td{
        background-color: $white;
      }

    }

    .rdtMonth, .rdtYear{
      background-color: $white;
    }

}

.emailStartMixrModal {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto !important;
  border-radius: 2rem;
  .plus {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    display: inline-block;
    img {
      height: $metric * 2;
      width: auto !important;
      margin: 0;
    }
  }

  .slackChannel {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin: auto;

    select {
      background-color: #ffffffd5;
      border-radius: 2 * $metric;
      padding: $metric/2 $metric;
      margin-top: 0;
    }

    .checkbox{
      padding: $metric;
    }
  }

  .selecting {
    vertical-align: top;
  }
  .time {
    text-align: left;
    margin-top: $metric * 1;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
    color: $white;

    .shuffle {
      height: 2 * $metric !important;
      width: fit-content !important;
      margin-right: $metric;
      filter: invert(1);
    }
    button {
      display: flex;
      align-items: center;
      width: fit-content;
      font-size: $font_size * 0.8;
      margin: 0;
      cursor: pointer;
      background-color: #0000005e;
      padding: $metric/2 $metric;
      margin: auto;
    }
  }

  h1,
  h3 {
    color: $white;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
  }

  .load {
    width: $metric * 20;
    height: $metric * 15;
    display: flex;
    align-items: center;
    * {
      margin: auto;
    }
  }

  .tournamentSelect {
    border-radius: 2 * $metric;
    border: $white $metric/2 solid;

    // text-align: left;
    cursor: pointer;
    display: inline-block;
    // height: $metric*50;
    min-width: 10 * $metric;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
      #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
    .time {
      text-align: left;
      margin-top: 0;
      span {
        // color: $shadow;
        font-size: $font_size * 0.9;
        margin: 0;
      }
    }
  }
  .gameContainer1 {
    // margin: auto;
    width: fit-content;
  }

  .gameContainer {
    display: flex;
    align-items: center;
    width: fit-content;
  }
  .rounds {
    font-size: $metric * 6;
    display: inline-block;
  }

  .selected {
    border: $yellow $metric/2 solid;
    .rounds {
      color: $yellow;
    }
  }
  .under {
    // border: rgb(190, 173, 76) $metric/2 solid;
  }

  .unselected {
    border: $darkgrey $metric/2 solid;

    // background: $white !important;
    // color: $black !important;
  }

  .gameLogoContainer {
    padding: 0;
    margin: $metric $metric;
    border: $metric/2 transparent solid;
    // background-color: #ffffff;
  }
  .in {
    // border-top: $metric/2 $yellow solid;
    border-bottom: $metric/2 $yellow solid;
  }

  .first {
    // border-left: $metric/2 $yellow solid;
  }
  .last {
    // border-right: $metric/2 $yellow solid;
  }

  .gameLogo {
    display: inline-block;
    height: $metric * 6;
    width: $metric * 6;
    // border-radius: $metric;
    //  background-color: $white;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    margin: 0;
    img {
      height: $metric * 6;
      width: auto !important;
      margin: 0;
    }
  }

  width: fit-content;
  p:nth-of-type(1) {
    margin: $metric * 4;
  }
  .url {
    overflow-wrap: anywhere;
    color: $purple;
  }
  a {
    color: #8e7eff;
  }
  .holder {
    background-color: rgb(255, 255, 255);
    border-radius: $metric * 2;
    padding: $metric * 1.5;
    margin: $metric * 2;
    h2 {
      color: $purple;
    }
    p {
      margin: $metric;
      max-width: 50*$metric;
      margin:auto;
    }

    .url {
      background: #a2a2a2;
      color: white;
      border-radius: $metric * 5;
      padding: 0;
      margin: $metric * 2 auto;
      padding-left: $metric * 2.5;
      width: 90%;
      max-width: 45 * $metric;
      display: flex;
      flex-direction: row;
      align-items: center;

      a {
        color: $white;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
      button {
        min-width: fit-content;
        height: $metric * 7.5;
        margin: 0;
        box-shadow: none;
        transition: 2s;
      }
    }
    .warning {
      color: #ff5959;
    }
  }
}

.manage_team {
  .member_cont {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    p {
      background: rgb(224, 224, 224);
      margin: $metric/2;
      padding: $metric/2 $metric * 2;
      width: fit-content;
      border-radius: $metric;
      align-self: flex-end;
      display: inline-block;
    }
    p.verified {
      background: rgb(224, 224, 224);
      align-self: flex-start;
    }
  }
  .processing_emails {
    display: flex;
    flex-wrap: wrap;
  }
}

.emailI {
  border-radius: 0 !important;
}
.or {
  margin-top: $metric * 2 !important;
}

.notAllowed {
  h2 {
    color: white;
  }
}

span.email-ids {
  float: left;
  padding: 4px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  background: #e5e5e5;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  transition: 0.2s;
  cursor: default;

  &:hover {
    background: #d9d9d9;
  }
}

span.cancel-email {
  width: 18px;
  display: block;
  float: right;
  text-align: center;
  margin-left: 20px;
  border-radius: 49%;
  height: 18px;
  color: #ff5959;
  background: white;
  line-height: 15px;
  margin-top: 1px;
  cursor: pointer;
  transition: 0.2s;
}
span.cancel-email:hover {
  background: #ff5959;

  color: white;
}
.col-sm-12.email-id-row {
}
.col-sm-12.email-id-row input {
  border: 0px;
  outline: 0px;
  background: #f3f3f3;
  width: 100%;
  cursor: text;
}
span.to-input {
  display: block;
  float: left;
  padding-right: 11px;
}
.col-sm-12.email-id-row {
  padding-top: 6px;
  padding-bottom: 7px;
}

@media (max-aspect-ratio: 16/9) {
  // .restContainer * {
  //   display: none !important;
  // }

  // .overContainer{
  // grid-template-columns: 0 100vw 0 !important;
  //     width: 33vw;
  //     height: 22vw;
  //     .position0 {
  //       width: 12vw !important;
  //       height: 12vw !important;
  //       transform: translateX(-0.5vw);
  //     }

  //     .position1 {
  //       width: 11vw !important;
  //       height: 11vw !important;
  //     }

  //     .position2 {
  //       width: 11vw !important;
  //       height: 11vw !important;
  // }
  // }
}

.invalidEmails {
  color: #ff5959;
  padding-top: $metric;
}

//Selection Screen


.data{
  background-color: $white;
  padding: $metric*4 $metric
}